import Vue from "vue";
import VueRouter from "vue-router";
import router from './router'
import store from './store'
import App from "./App.vue"
import './registerServiceWorker'
import Buefy from "buefy";
import VueHorizontalList from "vue-horizontal-list";
import "buefy/dist/buefy.css";

import { firestorePlugin } from 'vuefire';
import { Auth } from "@/firebase/auth";
import VueBottomNavigation from "bottom-navigation-vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueAddtohomescreen from "@owliehq/vue-addtohomescreen";
Vue.use(VueAddtohomescreen);




Vue.config.productionTip = false;
Vue.use(firestorePlugin);

Vue.use(VueBottomNavigation);


Vue.use(Buefy);
Vue.use(VueHorizontalList);
Vue.use(VueRouter);


Sentry.init({
  Vue,
  dsn: "https://e3ba6e643fc346a79379be2e0c2bb6f6@o395072.ingest.us.sentry.io/6137457",
  integrations: [
    Sentry.replayIntegration(),
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["run.getinshoot.com", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

Auth.onAuthStateChanged(user => {
  store.commit('setUser', user);
  new Vue({
    router,
    store,
    el: "#app",
    render: h => h(App)
  })
});